<template>
  <div class="container-fluid">
    <b-row>
      <b-col class="mt-2" :cols="responsiveCols">
        <b-row>
          <b-col cols="12">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.name') }}
            </label>
          </b-col>
          <b-col cols="12">
            <!-- Club name field -->
            <d-text-field
                v-model="club.name"
                :rules="validation.name"
                size="sm"
                error-text="validation.text-input-three-characters"
                class-name="background-light-blue-inputs"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <!-- Phone fields -->
          <b-col cols="6">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.fixPhoneNumber') }}
            </label>
          </b-col>
          <b-col cols="6">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.mobilePhoneNumber') }}
            </label>
          </b-col>
          <b-col cols="6">
            <!-- Fix phone field -->
            <d-text-field
                v-model="club.fixPhoneNumber"
                :rules="validation.fixPhoneNumber"
                size="sm"
                error-text="validation.phoneNumber"
                class-name="background-light-blue-inputs"
            />
          </b-col>
          <b-col cols="6">
            <!-- Mobile phone field -->
            <d-text-field
                v-model="club.mobilePhoneNumber"
                :rules="validation.mobilePhoneNumber"
                size="sm"
                error-text="validation.phoneNumber"
                class-name="background-light-blue-inputs"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <!-- Desription field -->
          <b-col cols="12">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.description') }}
            </label>
          </b-col>
          <b-col cols="12">
            <b-textarea
              v-model="club.description"
              size="sm"
              error-text="validation.default"
              class="background-light-blue-inputs"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="6">
            <label class="field-name-class">
              Latitude
            </label>
          </b-col>
          <b-col cols="6">
            <label class="field-name-class">
              Longitude
            </label>
          </b-col>
          <b-col cols="6">
            <d-text-field
              v-model="club.latitude"
              size="sm"
              class-name="background-light-blue-inputs"
            />
          </b-col>
          <b-col cols="6">
            <d-text-field
              v-model="club.longitude"
              size="sm"
              class-name="background-light-blue-inputs"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.tunnel') }}
            </label>
          </b-col>
          <b-col cols="12">
            <div id="booking-tunnel">
              <div
                class="mb-3 checkbox-container d-flex flex-row-reverse align-items-center justify-content-end"
                v-for="item in optionsFunnel"
              >
                <label class="m-0" :for="`checkbox-${item.name}`">{{ item.label }}</label>
                <input
                  class="mx-2"
                  type="checkbox"
                  :value="item.name"
                  v-model="selected"
                  @change="selectTunnel()"
                  :name="item.name"
                  :id="`checkbox-${item.name}`"
                >
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.sms-balance') }}
            </label>
          </b-col>
          <b-col cols="6">
            <label class="field-name-class">

            </label>
          </b-col>
          <b-col cols="6">
            <d-text-field
              v-model="club.smsAccountBalance"
              size="sm"
              class-name="background-light-blue-inputs"
              type="number"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col class="mt-2" :cols="responsiveCols">
        <b-row>
          <b-col cols="12">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.email') }}
            </label>
          </b-col>
          <b-col cols="12">
            <d-text-field
                v-model="club.email"
                :rules="validation.email"
                size="sm"
                error-text="validation.email"
                class-name="background-light-blue-inputs"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.address') }}
            </label>
          </b-col>
          <b-col cols="6">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.zipcode') }}
            </label>
          </b-col>
          <b-col cols="6">
            <d-text-field
                v-model="club.address"
                :rules="validation.address"
                size="sm"
                error-text="validation.required"
                class-name="background-light-blue-inputs"
            />
          </b-col>
          <b-col cols="6">
            <d-text-field
                v-model="club.zipCode"
                :rules="validation.zipCode"
                size="sm"
                error-text="validation.required"
                class-name="background-light-blue-inputs"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.city') }}
            </label>
          </b-col>
          <b-col cols="6">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.country') }}
            </label>
          </b-col>
          <b-col cols="6">
            <d-text-field
                v-model="club.city"
                :rules="validation.city"
                size="sm"
                :style="'height: 150px;'"
                error-text="validation.required"
                class-name="background-light-blue-inputs"
            />
          </b-col>
          <b-col cols="6">
            <d-select-option
                :options="countriesComputed"
                v-model="club.country"
                :rules="validation.country"
                :error-text="'validation.required'"
                :class-name="'background-light-blue-inputs'"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="6">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.timezone') }}
            </label>
          </b-col>
          <b-col cols="6">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.devise') }}
            </label>
          </b-col>
          <b-col cols="6">
            <d-select-option
                :options="timezonesComputed"
                v-model="club.timezone"
                :rules="validation.timezone"
                :error-text="'validation.required'"
                :class-name="'background-light-blue-inputs'"
            />
          </b-col>
          <b-col cols="6">
            <d-select-option
                :options="currencies"
                v-model="club.currency"
                :rules="validation.timezone"
                :error-text="'validation.required'"
                :class-name="'background-light-blue-inputs'"
            />
          </b-col>

          <b-col cols="12">
            <label class="field-name-class">
              {{ $t('admin.club_new.form.label.locale') }}
            </label>
          </b-col>
          <b-col cols="12">
            <d-select-option
                :options="locales"
                v-model="club.locale"
                :rules="validation.locale"
                :error-text="'validation.required'"
                :class-name="'background-light-blue-inputs'"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import countries from '@/data/countries.json'
import timezones from '@/data/timezones.json'
import currencies from '@/data/currencies.json'
import locales from '@/data/locales.json'
import Club from "@/classes/Club";
export default {
  data: () => ({
    selected: [],
    optionsFunnel: [
      // this.$t() method is not working here, don't know why.
      { name: 'classic', label: 'Réservation par créneaux' },
      { name: 'planning', label: 'Vue planning par zone' },
    ],
    countries: countries,
    timezones: timezones,
    currencies: currencies,
    locales: locales
  }),
  props: {
    club: {
      type: Object|Club,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => {
      },
    }
  },
  computed: {
    countriesComputed: function () {
      const data = [];
      if (Array.isArray(this.countries)) {
        this.countries.forEach(item => {
          data.push({
            text: item.name,
            value: item.code,
          });
        });
      }
      return data;
    },
    timezonesComputed: function () {
      const data = [];
      if (Array.isArray(this.timezones)) {
        this.timezones.forEach(item => {
          data.push({
            text: item,
            value: item,
          });
        });
      }
      return data;
    },
    responsiveCols: function () {
      if (this.$store.getters['layout/getInnerWidth'] < 991 && this.$store.getters['layout/getInnerWidth'] >= 700) {
        return 4;
      }
      if (this.$store.getters['layout/getInnerWidth'] < 700) {
        return 12;
      }
      return 6;
    },
  },
  methods: {
    selectTunnel() {
      this.club.appSportTimetableTypes = this.selected;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/checkbox/_checkbox";

label {
  user-select: none;
}

.customer-img {
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  object-fit: cover;
}
.product-box .product-img .product-hover {
  width: 170px;
  height: 100%;
}
@media screen and (max-width: 700px) {
  .product-box .product-img .product-hover {
    width: 100%;
  }
}
</style>
